import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { useWeb3Modal } from '@web3modal/react'
import { useAccount, useDisconnect } from 'wagmi'
import { sepolia } from 'viem/chains'

export default function ConnectButton() {
    const [loading, setLoading] = useState(false)
    const { open, setDefaultChain } = useWeb3Modal()
    const { isConnected, address } = useAccount()
    const { disconnect } = useDisconnect()
    const label = isConnected
        ? address?.replaceAll(address.slice(5, 38), '****')
        : 'Connect Wallet'

    async function onOpen() {
        setLoading(true)
        setDefaultChain(sepolia)
        await open()
        setLoading(false)
    }

    function onClick() {
        if (isConnected) {
            disconnect()
        } else {
            onOpen()
        }
    }

    return (
        <Button
            isLoading={loading}
            onClick={onClick}
            ml={{ base: 'auto', lg: '0' }}
        >
            {label}
        </Button>
    )
}
