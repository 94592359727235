import React from 'react'
import {
    Box,
    Button,
    Center,
    Container,
    Heading,
    Image,
    Text,
    Flex,
    UnorderedList,
    ListItem,
    Divider,
    useMediaQuery,
    InputGroup,
    Input,
    InputRightElement,
    ButtonGroup,
    StackDivider,
    HStack,
    VStack,
} from '@chakra-ui/react'
import Header from 'component/Header'
import BGImage from 'assets/images/lines-bg.png'
import AboutImage from 'assets/images/about-pic.png'
import UniswapImage from 'assets/images/uniswap-pic.png'
import Logo from 'assets/images/logo-09.png'
import BuyBg from 'assets/images/buy-card-bg.png'
import TokenBg from 'assets/images/token-bg.png'
import statBg from 'assets/images/stats-bg.png'
import MobileStatBg from 'assets/images/stats-bg-mobile.png'
import Tokenomics from 'assets/images/coin-stats.png'
import RoadMap from 'assets/images/phase.png'
import MobileRoadMap from 'assets/images/phases-mobile.png'
import StakeInfoBg from 'assets/images/stake-bg.png'
import {
    GetBUSDIcon,
    RewardIcon,
    SwapIcon,
    SwitchIcon,
    WalletIcon,
} from 'assets/icons'
import Footer from 'component/Footer'
import CoinPlatforms from 'component/coinPlatforms'
import { SwapWidget, Theme, darkTheme } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import Countdown from 'react-countdown'
import { MY_TOKEN_LIST, STAKING_ADDRESS, ESCO_ADDRESS } from 'utils/const'
import { useContractRead, useAccount, useContractWrite } from 'wagmi'
import StakeABI from 'utils/contracts/stake_contract.json'
import EscoABI from 'utils/contracts/esco_contract.json'
import { formatUnits } from 'viem'
import { useEffect, useState } from 'react'
import { readContract, waitForTransaction } from '@wagmi/core'
import { toast } from 'App'
// const ONE_DAY = 24*60*60*1000;
const ONE_DAY = 60 * 1000

export default function Landing() {
    const account = useAccount()
    const [investmentData, setInvestmentData] = useState<string[]>([])
    const [stakeAmount, setStakeAmount] = useState<string>('')
    const [isLoader, setLoader] = useState<boolean>(false)
    const [isLargerThan760] = useMediaQuery('(min-width: 760px)')
    const intoView = (id: string) => {
        const elem = document.getElementById(id)
        elem?.scrollIntoView({ behavior: 'smooth' })
    }
    const theme: Theme = {
        ...darkTheme,
        accent: '#F9DA0A',
        secondary: '#ffffff',
        fontFamily: `'Inter', sans-serif`,
        tokenColorExtraction: true,
    }

    const { data: totalInvestors } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'totalInvestors',
    })

    const { data: totalClaimedReward } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'totalClaimedReward',
    })

    const { data: totalStakedAmount } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'totalStakedAmount',
    })

    const { data: currentPercentage } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'currentPercentage',
    })

    const { data: getRewardBalance } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'getRewardBalance',
    })

    const { data: launchTime } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'LAUNCH_TIME',
    })

    const { data: userBalance } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: ESCO_ADDRESS,
        abi: EscoABI,
        functionName: 'balanceOf',
        args: [account?.address],
    })

    const { data: userInvestments,refetch:refetchUserInvestment } = useContractRead<
        readonly unknown[],
        string,
        string
    >({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'getUserInvestments',
        args: [account?.address],
    })

    const {
        writeAsync: stakeEsco,
        error: stakeEscoError,
        isLoading: stakeEscoLoading,
        reset: stakeEscoReset,
    } = useContractWrite({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'stakeEsco',
    })

    const {
        writeAsync: escoApprove,
        isLoading: escoApproveLoading,
        error: escoApproveError,
        reset: escoApproveReset,
    } = useContractWrite({
        address: ESCO_ADDRESS,
        abi: EscoABI,
        functionName: 'approve',
    })

    useEffect(() => {
        if (userInvestments?.length) {
            callbaclFunc()
        }
    }, [])

    const callbaclFunc = async () => {
        
        await refetchUserInvestment();
        let dataArr: any = await Promise.all(
            (userInvestments as any)?.map((item: string) => {
                const investmentsData = readContract({
                    address: STAKING_ADDRESS,
                    abi: StakeABI,
                    functionName: 'investments',
                    args: [item],
                })
                return investmentsData
            }),
        )
        dataArr = dataArr?.map((item: any, index: number) => ({
            id: (userInvestments as any)[index].toString(),
            investorAddress: item[0],
            totalInvestment: formatUnits(item[1], 18),
            startDate: item[2],
            claimedDate: item[3],
            rewardAmount: item[4],
            rewardPercentage: formatUnits(item[5], 16),
            isWithdrawn: item[6],
        }))
        setInvestmentData(dataArr)
    }

    const handleApprove = async () => {
        setLoader(true)
        const trx = await escoApprove({
            args: [STAKING_ADDRESS, parseFloat(stakeAmount) * 10 ** 18],
        })

        const isCompleted = await waitForTransaction({
            hash: trx?.hash,
        })
        if (isCompleted) {
            const isStake = await stakeEsco({
                args: [parseFloat(stakeAmount) * 10 ** 18],
            })
            const isCompletedEsco = await waitForTransaction({
                hash: isStake?.hash,
            })
            if (isCompletedEsco) {
                setLoader(false)
                setTimeout(() => {
                    callbaclFunc()
                }, 3000)
            }
        }
    }

    useEffect(() => {
        if (stakeEscoError) {
            toast({ status: 'error', description: stakeEscoError?.message })
            stakeEscoReset()
        }
    }, [stakeEscoError])

    useEffect(() => {
        if (escoApproveError) {
            toast({ status: 'error', description: escoApproveError?.message })
            escoApproveReset()
        }
    }, [escoApproveError])

    // console.log('totalClaimedReward', totalClaimedReward)
    return (
        <>
            <Header />
            <Center
                as="section"
                minH={{ base: 'auto', md: '100vh' }}
                p={{ base: '120px 0 60px', md: '175px 0 60px' }}
                bg={{ md: `url(${BGImage}) no-repeat center top` }}
                bgSize={{ md: 'cover' }}
            >
                <Container maxW="1045px" textAlign="center">
                    {false && <img src={Logo} />}
                    <Heading fontSize={{ base: '39px', md: '75px' }}>
                        Discover ESCO, the Profit Maker - known to those who
                        know him best.
                    </Heading>
                    <Text my="60px" variant="gotham">
                        $ESCO, The Coin that puts the “fun” in “funds”! If you
                        know who ESCO is, then you know he’s the king of the
                        crypto cartel. And if you don’t know who he is, well,
                        let’s just say you’re not invited to the party.
                    </Text>
                    <Button onClick={() => intoView('#how-to-buy')}>
                        Buy Now
                    </Button>
                    <CoinPlatforms />
                </Container>
            </Center>

            <Box
                as="section"
                py={{ base: '40px', md: '150px' }}
                id="#about"
                bg="primary.600"
            >
                <Container maxW="1280px">
                    <Flex
                        justifyContent={{ base: 'center', md: 'space-between' }}
                        direction={{ base: 'column', md: 'row' }}
                        gap="30px"
                        textAlign={{ base: 'center', md: 'left' }}
                    >
                        <Image
                            maxW="444px"
                            w="100%"
                            mx="auto"
                            src={AboutImage}
                        />
                        <Box
                            mt={{ base: '40px', md: 0 }}
                            order={{ base: '-1', md: '1' }}
                        >
                            <Heading as="h2" variant="primary">
                                About Us
                            </Heading>
                            <Text
                                mt="45px"
                                maxW="596px"
                                mx="auto"
                                variant="gotham"
                            >
                                Introducing $ESCO, the Coin that puts the “fun”
                                in “funds”! If you know who ESCO is, then you
                                know he’s the king of the crypto cartel. And if
                                you don’t know who he is, well, let’s just say
                                you’re not invited to the party.
                                <br />
                                <br />
                                $ESCO is not your average meme Coin. It’s a Coin
                                with a purpose. A purpose to provide a fun and
                                lighthearted approach to the world of
                                cryptocurrency, while also offering potential
                                value to our holders. We believe that
                                cryptocurrency should be accessible and
                                enjoyable for everyone, and {`that's`} why we
                                created $ESCO. {`It's`} a coin that you can
                                hold, trade, and even share with your friends as
                                a playful way to participate in the crypto
                                community.
                            </Text>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            <Box as="section" my={{ base: '40px', md: '100px' }}>
                <Container maxW="1280px">
                    <Heading as="h2" variant="primary" textAlign="center">
                        Stats
                    </Heading>
                    <Flex
                        bg={{
                            base: `url(${MobileStatBg}) no-repeat center`,
                            md: `url(${statBg}) no-repeat center`,
                        }}
                        bgSize={{ base: '100% 100%', md: '100% 100%' }}
                        mt="60px"
                        justify="center"
                        align="center"
                        p="120px 16%"
                    >
                        <Box
                            as={isLargerThan760 ? HStack : VStack}
                            divider={
                                <StackDivider
                                    borderColor="white"
                                    maxH={{ md: '45px' }}
                                />
                            }
                            maxW="1000px"
                            w="100%"
                            align="center"
                            textAlign="center"
                            justify="space-around"
                            spacing={{ base: '20px', md: '0' }}
                        >
                            <StatValues
                                label="Investor"
                                value={totalInvestors?.toString()}
                            />
                            <StatValues
                                label="Claimed Reward"
                                value={
                                    totalClaimedReward &&
                                    parseFloat(
                                        formatUnits(
                                            BigInt(totalClaimedReward),
                                            18,
                                        ),
                                    )?.toFixed(2)
                                }
                            />
                            <StatValues
                                label="Staked Amount"
                                value={
                                    totalStakedAmount &&
                                    Intl.NumberFormat('en', {
                                        notation: 'compact',
                                    }).format(
                                        parseFloat(
                                            formatUnits(
                                                BigInt(totalStakedAmount),
                                                18,
                                            ),
                                        ),
                                    )
                                }
                            />
                            <StatValues
                                label="Reward Percentage"
                                value={
                                    currentPercentage &&
                                    parseFloat(
                                        formatUnits(
                                            BigInt(currentPercentage),
                                            16,
                                        ),
                                    )?.toFixed(2)
                                }
                            />
                            <StatValues
                                label="Current Reward Balance"
                                value={
                                    getRewardBalance &&
                                    parseFloat(
                                        formatUnits(
                                            BigInt(getRewardBalance),
                                            18,
                                        ),
                                    )?.toFixed(2)
                                }
                            />
                        </Box>
                    </Flex>
                </Container>
            </Box>
            <Box as="section" my={{ base: '40px', md: '150px' }} id="#stake">
                <Container maxW="1280px" fontFamily={`'Inter', sans-serif`}>
                    <Heading as="h2" variant="primary" textAlign="center">
                        Stake
                    </Heading>
                    <Box
                        border="1px solid black"
                        rounded="8px"
                        mt="40px"
                        maxW="780px"
                        mx="auto"
                        p="24px"
                    >
                        <Flex justify="space-between" align="center" pb="20px">
                            <Flex gap="10px" align="center" fontSize="24px">
                                <Image
                                    w="80px"
                                    src="https://escocoin.com/static/media/logo-09.884e2cc4f9972e8b57a4.png"
                                />
                                $ESCO
                            </Flex>
                            <Text as="span" fontSize="24px">
                                {parseFloat(
                                    formatUnits(BigInt(userBalance || '0'), 18),
                                )}
                            </Text>
                        </Flex>
                        <Divider />
                        <Flex
                            pt="30px"
                            gap="20px"
                            direction={{ base: 'column', md: 'row' }}
                            justify="center"
                        >
                            <InputGroup
                                flex={1}
                                rounded="8px"
                                flexWrap={{ base: 'wrap', md: 'nowrap' }}
                            >
                                <Input
                                    focusBorderColor="black"
                                    borderColor="black"
                                    size="lg"
                                    bg="black"
                                    type="number"
                                    placeholder="Enter Amount"
                                    color="white"
                                    value={stakeAmount}
                                    onChange={e =>
                                        setStakeAmount(e?.target?.value)
                                    }
                                    _placeholder={{
                                        color: 'white',
                                    }}
                                />
                                <InputRightElement
                                    w={{ base: '100%', md: 'auto' }}
                                    pb={{ base: '0px', md: 0 }}
                                    pt="10px"
                                    pr="10px"
                                    position={{
                                        base: 'relative',
                                        md: 'absolute',
                                    }}
                                >
                                    <ButtonGroup>
                                        <Button
                                            variant="unstyled"
                                            onClick={e =>
                                                setStakeAmount('1000')
                                            }
                                        >
                                            Min 1000 $ESCO
                                        </Button>
                                        <Button
                                            variant="unstyled"
                                            onClick={e => {
                                                const bal = parseFloat(
                                                    formatUnits(
                                                        BigInt(
                                                            userBalance || '0',
                                                        ),
                                                        18,
                                                    ),
                                                )
                                                if (userBalance && bal) {
                                                    setStakeAmount(
                                                        bal.toString(),
                                                    )
                                                }
                                            }}
                                        >
                                            Max
                                        </Button>
                                    </ButtonGroup>
                                </InputRightElement>
                            </InputGroup>
                            <Button
                                w="120px"
                                mx="auto"
                                minW={{ base: '120px', md: '190px' }}
                                onClick={() => handleApprove()}
                                isLoading={
                                    escoApproveLoading ||
                                    stakeEscoLoading ||
                                    isLoader
                                }
                            >
                                {launchTime &&
                                new Date(
                                    parseInt(launchTime?.toString()) * 1000 +
                                        7 * ONE_DAY,
                                ).getTime() > Date.now() ? (
                                    <Countdown
                                        date={
                                            new Date(
                                                parseInt(
                                                    launchTime?.toString(),
                                                ) *
                                                    1000 +
                                                    7 * ONE_DAY,
                                            )
                                        }
                                    />
                                ) : (
                                    'Stake Now'
                                )}
                            </Button>
                        </Flex>
                    </Box>

                    <Flex
                        justifyContent="center"
                        gap="30px"
                        mt={{ base: '40px', md: '100px' }}
                        flexWrap="wrap"
                        direction={{ base: 'column', md: 'row' }}
                    >
                        {investmentData &&
                            investmentData
                                ?.filter((item: any) => !item?.isWithdrawn)
                                .map((investment: any) => (
                                    <StackInfoCard
                                        key={investment?.date}
                                        amount={investment?.totalInvestment}
                                        message={
                                            <Countdown
                                                date={
                                                    new Date(
                                                        parseInt(
                                                            investment?.startDate?.toString(),
                                                        ) *
                                                            1000 +
                                                            7 * ONE_DAY,
                                                    )
                                                }
                                            />
                                        }
                                        isClaim={
                                            parseInt(
                                                investment?.startDate?.toString(),
                                            ) *
                                                1000 >=
                                            Date.now()
                                        }
                                        id={investment?.id}
                                    />
                                ))}

                        {/* <StackInfoCard
                            amount={1900.43}
                            rewardAmount={0.0}
                            type="reward"
                            message={
                                <>
                                    No profit has been made in this pool.{' '}
                                    <TimeIcon />
                                </>
                            }
                            id=""
                        />
                        <StackInfoCard
                            amount={1400.43}
                            rewardAmount={1400.2}
                            type="reward"
                            isClaim
                            message={
                                <>
                                    Your reward has been arrived. <TimeIcon />
                                </>
                            }
                            id=""
                        /> */}
                    </Flex>
                </Container>
            </Box>
            <Box
                as="section"
                mt={{ base: '30px', md: '60px' }}
                id="#how-to-buy"
            >
                <Container maxW="1280px" textAlign="center">
                    <Heading as="h2" variant="primary">
                        How to buy
                    </Heading>
                    <Text maxW="590px" variant="gotham" mx="auto" mt="30px">
                        Ready to join the $ESCO Cartel? Here are the steps to
                        follow
                    </Text>
                    <Flex
                        flexWrap="wrap"
                        justifyContent={{ base: 'center', lg: 'space-between' }}
                        gap="70px"
                        maxW="1000px"
                        mx="auto"
                    >
                        <BuyCard
                            icon={<WalletIcon />}
                            title="Create a wallet"
                            message="Download MetaMask or any wallet of your choice from the app store or Google Play store for free. Desktop users can download the Google Chrome extension by going to metamask.io"
                        />
                        <BuyCard
                            icon={<GetBUSDIcon />}
                            title="Get some ETH"
                            message="Have ETH in your wallet, which you can swap for $ESCO. If you don’t have any ETH, you can buy it directly on MetaMask, transfer from another wallet, or buy it on another exchange and send it to your wallet."
                        />
                        <BuyCard
                            icon={<SwapIcon />}
                            title="Go to Uniswap"
                            message="Go to Uniswap and connect your wallet. Enter ETH amount you want to swap and click 'swap'. When MetaMask prompts you for a wallet signature, confirm your transaction."
                        />
                        <BuyCard
                            icon={<SwitchIcon />}
                            title="Switch ETH to $ESCO"
                            message="Your ETH will be deducted, and your wallet will be topped up with $ESCO."
                        />
                    </Flex>
                </Container>
            </Box>
            <Box as="section" py={{ base: '40px', md: '150px' }} id="#swap">
                <Container maxW="1280px">
                    <Flex
                        justifyContent="space-between"
                        direction={{ base: 'column', md: 'row' }}
                        gap="25px"
                        align="center"
                    >
                        <Box
                            maxW="444px"
                            w="100%"
                            order={{ base: '1', md: 'unset' }}
                        >
                            <Image w="100%" src={UniswapImage} />
                        </Box>
                        <Box maxW="596px" w="100%" mt={{ base: '40px', md: 0 }}>
                            <Heading as="h2" variant="primary">
                                UniSwap
                            </Heading>
                            <Text variant="gotham" mt="20px">
                                Join the $ESCO Cartel with immediate ESCO Cartel
                                swap.
                            </Text>

                            <Box mt="0px" maxW="600px" p="32px 16px">
                                <SwapWidget
                                    jsonRpcUrlMap={{
                                        1: [
                                            'https://mainnet.infura.io/v3/5a65431a2f0848db9b6c2283e70f523f',
                                        ],
                                    }}
                                    theme={theme}
                                    width="100%"
                                    tokenList={MY_TOKEN_LIST}
                                    defaultOutputTokenAddress="0x7163436b8EfFfB469F6BB81cc908b1661d4795e6"
                                />
                            </Box>
                        </Box>
                    </Flex>
                </Container>
            </Box>
            <Box
                as="section"
                py={{ base: '60px', md: '100px' }}
                bg={`url(${TokenBg}) no-repeat bottom`}
                bgSize="cover"
                id="#token"
            >
                <Container maxW="1280px">
                    <Flex
                        justifyContent="space-between"
                        align="center"
                        direction={{ base: 'column', md: 'row' }}
                    >
                        <Box mb={{ base: '40px', md: 0 }}>
                            <Heading
                                as="h2"
                                variant="secondary"
                                color="primary.500"
                            >
                                Tokenomics
                            </Heading>
                            <UnorderedList
                                mt="45px"
                                maxW="580px"
                                spacing="15px"
                            >
                                <ListItem>
                                    Total supply: 330,000,000,000,000
                                </ListItem>
                                <ListItem>
                                    93% of the Coins are allocated to a
                                    liquidity pool.
                                </ListItem>
                                <ListItem>
                                    7% of the Coins will be allocated for
                                    centralized exchange listing and development
                                    will happen in a multi-signature wallet.
                                </ListItem>
                                <ListItem>
                                    The name {`"ESCO"`} is a play on words, with
                                    a nod to the idea of paying taxes. However,
                                    unlike other coins that may have fees or
                                    taxes associated with them, $ESCO is
                                    different. This time, there is NO TAX. Join
                                    us and {`let's`} have some fun with $ESCO!
                                </ListItem>
                            </UnorderedList>
                        </Box>
                        <Image maxW="480px" w="100%" src={Tokenomics} />
                    </Flex>
                </Container>
            </Box>
            <Box
                as="section"
                py={{ base: '40px', md: '150px' }}
                id="#map"
                bg="primary.600"
                textAlign="center"
            >
                <Container maxW="1280px">
                    <Heading as="h2" variant="primary">
                        Road Map
                    </Heading>
                    <Text variant="gotham" my="40px" maxW="770px" mx="auto">
                        The following is a basic outline of the trajectory that
                        $ESCO intends to take moving forward. However, we do not
                        plan to reveal all of our plans immediately. Anticipate
                        unexpected developments throughout the journey.
                    </Text>
                    <Image
                        maxW="1080px"
                        w="100%"
                        mx="auto"
                        src={isLargerThan760 ? RoadMap : MobileRoadMap}
                    />
                </Container>
            </Box>
            <Footer />
        </>
    )
}

function StatValues({
    label,
    value,
}: {
    label: string
    value?: string | number
}) {
    return (
        <Box>
            <Text variant="gotham" color="white" p="3px 7px" bg="black">
                {label}
            </Text>
            <Text variant="gotham" fontSize="18px" mt="12px">
                {value}
            </Text>
        </Box>
    )
}

function StackInfoCard({
    amount,
    message,
    type,
    rewardAmount,
    isClaim,
    id,
}: {
    amount: number
    message: string | JSX.Element
    type?: string
    rewardAmount?: number
    isClaim?: boolean
    id: string
}) {
    const {
        write: unStakeAmount,
        error: unStakeAmountError,
        isLoading: unStakeAmountLoading,
        isSuccess: unStakeAmountSuccess,
    } = useContractWrite({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'unstakeAmount',
    })

    const {
        write: claimReward,
        error: claimRewardError,
        isLoading: claimRewardLoading,
        isSuccess: claimRewardSuccess,
    } = useContractWrite({
        address: STAKING_ADDRESS,
        abi: StakeABI,
        functionName: 'claimReward',
    })

    return (
        <Box
            bg={`url(${StakeInfoBg}) no-repeat`}
            bgSize="100% 94%"
            minW="280px"
            p="25px 25px 0px"
            maxW="375px"
            w="100%"
        >
            <Text fontSize="14px">Amount</Text>
            <Text>
                <Text as="span" fontSize="26px" variant="black">
                    {amount}
                </Text>{' '}
                $ESCO
            </Text>

            <Text
                bg="linear-gradient(180deg, #F9DA0A 0%, #C19A00 154.17%)"
                p="16px 12px"
                my="15px"
                minH="75px"
                border="1px solid white"
                fontSize="16px"
            >
                {type === 'reward' ? (
                    <Flex justify="space-between" align="center">
                        <Text as={Flex} variant="primary" gap="10px">
                            <RewardIcon /> Reward
                        </Text>
                        <Text as="span" variant="primary">
                            <Text as="strong" fontSize="26px" variant="black">
                                {rewardAmount}
                            </Text>{' '}
                            $ESCO
                        </Text>
                    </Flex>
                ) : (
                    <Center minH="43px">
                        Get reward at the end of locked period.
                    </Center>
                )}
            </Text>
            <Text
                color="primary.500"
                bg="black"
                fontSize="14px"
                p="4px 6px"
                mb="35px"
                textAlign="center"
                variant="inter"
            >
                {type === 'reward' ? '' : `Reward in `}
                {message}
            </Text>
            <ButtonGroup display="flex" justifyContent="flex-end">
                {isClaim ? (
                    <Button
                        minW="120px"
                        variant="outline"
                        onClick={() => claimReward({ args: [id] })}
                        isLoading={claimRewardLoading}
                    >
                        Claim Reward
                    </Button>
                ) : (
                    <Button
                        minW="120px"
                        isLoading={unStakeAmountLoading}
                        onClick={() => unStakeAmount({ args: [id] })}
                    >
                        Unstake
                    </Button>
                )}
            </ButtonGroup>
        </Box>
    )
}

function BuyCard({
    icon,
    title,
    message,
}: {
    icon: JSX.Element
    title: string
    message: string
}) {
    return (
        <Box
            mt="160px"
            bg={`url(${BuyBg}) no-repeat`}
            bgSize="100% 100%"
            maxW="393px"
        >
            <Center m="-110px 0 20px">{icon}</Center>
            <Text
                bg="black"
                border="5px solid #F2F2F2"
                fontSize="25px"
                color="white"
                variant="gotham"
                p="20px 10px"
            >
                {title}
            </Text>
            <Text p="30px 30px 80px" variant="gotham">
                {message}
            </Text>
        </Box>
    )
}
