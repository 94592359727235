export const menu = [
    {
        label: 'About Us',
        path: '#about',
    },
    {
        label: 'Stake',
        path: '#stake',
    },
    {
        label: 'How to buy',
        path: '#how-to-buy',
    },
    {
        label: 'UniSwap',
        path: '#swap',
    },
    {
        label: 'Tokenomics',
        path: '#token',
    },
    {
        label: 'Road Map',
        path: '#map',
    },
]

export const MY_TOKEN_LIST = [
    // {
    //     chainId: 1,
    //     name: 'Tether',
    //     address: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
    //     decimals: 18,
    //     symbol: 'USDT',
    //     logoURI:
    //         'https://s2.coinmarketcap.com/static/img/coins/64x64/825.png',
    // },
    {
        name: 'Esco coin',
        address: '0x7163436b8EfFfB469F6BB81cc908b1661d4795e6',
        symbol: 'ESCO',
        decimals: 18,
        chainId: 1,
        logoURI:
            'https://escocoin.com/static/media/logo-09.884e2cc4f9972e8b57a4.png',
    },
]

export const PROJECT_ID = '004a83fdddf9a604c2156ab0611dfa3b'
export const STAKING_ADDRESS = '0x6f09E1619805c070e0Ec6701143aE591D4B1f593'
export const ESCO_ADDRESS = '0x893353Dd731Aa428B083EbB73d3eAedb7Af5d6dA'
